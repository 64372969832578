import React from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import Footer from '../components/Footer/Footer'
import PostHeader from '../components/PostHeader/PostHeader'
import Layout from '../components/Layout/Layout'

const fourOhFour = () => (
  <Layout>
    <Helmet>
      <title>{`404 | ${config.siteTitle}`}</title>
    </Helmet>
    <PostHeader config={config} />

    <main className="container grid grid--outer">
      <h1 style={{textAlign: "center", gridColumn: "start/end"}}>Oops.. I totally just 404ed</h1>
    </main>

    {/* footer */}
    <Footer config={config} />
  </Layout>
);


export default fourOhFour;